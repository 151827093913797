<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Pedidos - Listar</strong>
          </CCardHeader>
          <CCardBody>
            <b-row>
              <b-col sm="12" md="5"></b-col>
              <b-col sm="12" md="2">
                  <b-form-group label="Desde :">
                    <b-form-input @change="ListOrder" class="text-center" :max="to" type="date"  ref="to" v-model="from"></b-form-input>
                    <small v-if="errors.from" class="form-text text-danger" >Selccione una fecha</small>
                  </b-form-group>
                </b-col>

                <b-col sm="12" md="2">
                  <b-form-group label="Hasta :">
                    <b-form-input @change="ListOrder" class="text-center" :min="from" type="date"  ref="from" v-model="to"></b-form-input>
                    <small v-if="errors.to" class="form-text text-danger" >Selccione una fecha</small>
                  </b-form-group>
                </b-col>
              <b-col sm="6" md="3">
                <b-form-group label=".">
                  <b-input-group >
                    <b-form-input v-model="search" class="form-control"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="ListOrder">
                        <b-icon icon="search"></b-icon
                      ></b-button>
                    </b-input-group-append>
                  </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="10%" class="text-center">Fecha</th>
                    <th width="8%" class="text-center">N° Pedido</th>
                    <th width="30%" class="text-center">Email</th>
                    <th width="8%" class="text-center">Celular</th>
                    <th width="5%" class="text-center">Moneda</th>
                    <th width="8%" class="text-center">M. de Pago</th>
                    <th width="5%" class="text-center">Total</th>
                    <th width="7%" class="text-center">Estado</th>
                    <th width="7%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="item.id">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.registration_date }}</td>
                    <td class="text-center">{{ item.order_number }}</td>
                    <td class="text-left">{{ item.email }}</td>
                    <td class="text-left">{{ item.phone }}</td>
                    <td class="text-center">{{ item.currency_type }}</td>
                    <td class="text-left">{{ item.payment_method }}</td>
                    <td class="text-right">{{ item.total }}</td>
                    <td class="text-center">
                      <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                      <b-badge v-if="item.state == 1" variant="warning">Pendiente</b-badge>
                      <b-badge v-if="item.state == 2" variant="info">En Proceso</b-badge>
                      <b-badge v-if="item.state == 3" variant="success">Realizado</b-badge>
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('OrderEdit') && item.state == 2" @click="EditOrder(item.id_order)">Editar</b-dropdown-item >
                        <b-dropdown-item v-if="Permission('OrderView')" @click="ViewOrder(item.id_order)">Ver</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('SaleAdd') && item.state == 3" @click="AddSale(item.id_order)">Facturar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>

            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="ListOrder" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";
var moment = require("moment");
import CodeToName from "@/assets/js/CodeToName";

export default {
  name: "UsuarioList",
  data() {
    return {
      module:'Order',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      to:moment(new Date()).local().format("YYYY-MM-DD"),
      from:moment().subtract(30, 'days').local().format("YYYY-MM-DD"),
      search: "",
      data_table: [],
      errors:{
        to:false,
        from:false,
      },
    };
  },
  mounted() {
    this.ListOrder();
  },
  methods: {
    ListOrder,
    EditOrder,
    ViewOrder,
    Permission,
    CodeDocumentType,
    AddSale,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};
function CodeDocumentType(code) {
  return CodeToName.NameDocumentType(code);
}

function AddSale(id_order) {
  this.$router.push({
    name: "SaleOrderAdd",
    params: { id_order: je.encrypt(id_order) },
  });
}
//listar usuario
function ListOrder() {
  
  this.errors.from = false;
  this.errors.to = false;

  if (this.from.length == 0) {this.errors.from = true; return false;}
  if (this.to.length == 0) {this.errors.to = true; return false;}
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url =
    this.url_base + "order/list/"+this.from+"/"+this.to+"/" + search + "?page=" + this.currentPage;

  axios({
    method: "GET",
    url: url,
    headers: {
      token: this.token,
      module: this.module,
      role: 1,
    },
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditOrder(id_order) {
  this.$router.push({
    name: "OrderEdit",
    params: { id_order: je.encrypt(id_order) },
  });
}

// Ver Usuario
function ViewOrder(id_order) {
  this.$router.push({
    name: "OrderView",
    params: { id_order: je.encrypt(id_order) },
  });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
